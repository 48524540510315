// Core variables and mixins
@import "variables";
@import "mixins";
// Global CSS
@import "global";
// Components
@import "navbar";
@import "masthead";
@import "bootstrap-overrides";

/* You can add global styles to this file, and also import other style files */
html, body {
  height: 100%;

  html[lang="en"] & {
    font-family: "Inter";
    h1, h2, h3, h4, h5, h6 { font-family: "Inter"; }
    label { font-family: "Inter"; }

    @media only screen and (max-width: 480px) {
      font-size: 18px;
      line-height: 1.5;
      a, span {
        font-size: 18px !important;
        line-height: 1.5;
      }
    }
  }

  html[lang="th"] & {
    font-family: "NotoSans";
    font-weight: 200;
    h1, h2, h3, h4, h5, h6 { font-family: "NotoSans"; }
    label { font-family: "NotoSans"; }

    @media only screen and (max-width: 480px) {
      font-size: 20px;
      line-height: 1.5;
      a, span {
        font-size: 20px !important;
        line-height: 1.5;
      }
    }
  }
}

body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #EAEAEE;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.loading {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(201, 201, 201, 0.8);

  .loader {
    width: 64px;
    height: 64px;
    margin: 2px auto;
    border: 8px solid #f3f3f3;
    border-top: 8px solid #265C7E;
    border-radius: 50%;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    /* Safari */
    @-webkit-keyframes spin {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }
}

.fullscreen-dialog {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  & .mat-dialog-container {
    overflow: hidden;
    border-radius: 0 !important;
  }
}

.mobile-dialog {
  & .mat-dialog-container {
    height: auto;
    overflow: hidden;
    margin: 20px 0;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
    height: 100%;
    max-width: 100% !important;
    max-height: 100% !important;
    & .mat-dialog-container {
      margin: 0;
      border-radius: 0 !important;
    }
  }
}

.mobile-half-dialog {
  @media only screen and (max-width: 480px) {
    width: 100%;
    // height: 72%;
    max-width: 100% !important;
    max-height: 100% !important;
    position: fixed !important;
    bottom:0 !important;
    & .mat-dialog-container { 
      max-height: 100% !important;
      border-radius: 0 !important; 
      border-top-left-radius: 30px !important;
      border-top-right-radius: 30px !important;
      .mat-dialog-content { max-height: 100% !important; }
    }
  }
}

.mobile-notice-dialog {
  @media only screen and (max-width: 480px) {
    width: 90%;
    max-width: 100% !important;
    & .mat-dialog-container { width: 100%; }
  }
}

.desktop-notice {
  ::ng-deep .cdk-overlay-pane {
    margin-top:20px;
    top:49px !important;
    &::after {
      top: -10px;
      left: 115px;
      width: 0;
      height: 0;
      content: '';
      position: absolute;
      border-left: 0.7rem solid transparent;
      border-right: 0.7rem solid transparent;
      border-bottom: 0.7rem solid white;
    }
  }
}

.mat-select-class {
  left: 0; transform:none !important;
}

@media only screen and (max-width: 2560px) {
  html, body { zoom: 1.1; }
}
@media only screen and (max-width: 1920px) {
  html, body { zoom: 1.0; }
}
@media only screen and (max-width: 1440px) {
  html, body { zoom: 0.9; }
}
@media only screen and (max-width: 1024px) {
  html, body { zoom: 0.8; }
}
@media only screen and (max-width: 768px) {
  html, body { zoom: 0.7; }
}

@media only screen and (min-width: 480px) {
  .on-mobile { display: none !important; }
}
@media only screen and (max-width: 480px) {
  .on-laptop { display: none !important; }

  html, body { zoom: 0.84 !important;  }
}
@media only screen and (max-width: 376px) {
  html, body { zoom: 0.78 !important; }
}
@media only screen and (max-width: 324px) {
  html, body { zoom: 0.74 !important; }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}