
@import "~@openfonts/inter_all/index.css";
@import "~@openfonts/prompt_thai/index.css";
@import "~@openfonts/bai-jamjuree_thai/index.css";

@mixin heading-font {
  html[lang="en"] & {
    font-family: "Inter";
    font-weight: 600 !important;
  }
  html[lang="th"] & {
    font-family: "NotoSans";
    font-weight: 500 !important;
  }
}
@mixin body-font {
  html[lang="en"] & { font-family: "Inter"; }
  html[lang="th"] & { font-family: "NotoSans"; }
}

@font-face {
  font-family: 'NotoSans';
  src: url('./../font/NotoSansThai/NotoSansThai-Regular.ttf') format('truetype')
}